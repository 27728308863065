import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomCheckbox } from "../components/checkbox";
import { LogBackground4 } from "../pngs";
import {
  useCreateUserMutation,
  useGetUsersListQuery,
} from "../components/apis/userDetailsAPi";
import { useEffect, useState } from "react";
import { ROUTES } from "../components/consts/routes.consts";
import { useNavigate } from "react-router-dom";
import { themeColors, themeFonts } from "../configs";
import { useGetArtistListQuery } from "../components/apis/artistsApi";
import { useGetCuratorsListQuery } from "../components/apis/curatorsApi";
import { useUserVisitMutation } from "../components/apis/userApi";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TermsConditions from "./home/privacy-terms/privacy-terms";
export const Callback = ({
  trackId,
  playlistId,
  curatorName,
  referrerId,
}: {
  trackId?: any;
  playlistId?: any;
  curatorName: any;
  referrerId?: any;
}) => {
  const isSmallScreen = useMediaQuery("(max-width:995px)");

  const { data, refetch, isLoading } = useGetUsersListQuery<any>();
  const isMiniScreen = useMediaQuery("(max-width:320px)");
  if (data?.user?.isBan === false) {
    window.localStorage.setItem("id", data?.user?._id);
    window.localStorage.setItem("userId", data?.user?.spotifyId);
    window.localStorage.setItem("userName", data?.user?.name);
    window.localStorage.setItem("userEmail", data?.user?.email);
    window.localStorage.setItem("userCredits", data?.user?.userCredits);
    window.localStorage.setItem("userProfileImage", data.user?.image[0]?.url);
  }
  if (data?.user?.isBan === true) {
    setTimeout(() => {
      window.localStorage.removeItem("refresh_token");
      window.localStorage.removeItem("token");
    }, 1000);
  }
  const callBack = "callback";
  const { refetch: artistRefetch } = useGetArtistListQuery<any>(
    { trackId: trackId },
    {
      skip: !trackId,
    }
  );
  const { refetch: curatorRefetch } = useGetCuratorsListQuery<any>(
    { playlistId: playlistId },
    {
      skip: !playlistId,
    }
  );
  const id = data?.user?._id;
  const [emailNotification, setEmailNotification] = useState(false);
  const [termsOptIn, setTermsOptIn] = useState(false);
  const [createUser] = useCreateUserMutation();
  const [visitUser] = useUserVisitMutation();
  const showButton = true;
  const disabled = termsOptIn === false;
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const isLoggedIn = token && userId !== "undefined";
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const user = {
        emailNotification,
        termsOptIn,
      } as any;
      if (referrerId && data?.userExist === false) {
        user.referrerId = referrerId;
      }
      await createUser(user).unwrap();
      refetch();
    } catch (error: any) {}
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (data?.userExist === true && data?.user?.isBan === false && isLoggedIn) {
      if (!trackId && !playlistId) {
        navigate(ROUTES.DASHBOARD);
      }
      if (trackId && curatorName) {
        artistRefetch({ trackId: trackId }).then((response: any) => {
          if (response.status === "fulfilled") {
            navigate(ROUTES.SUBMIT_A_SONG_1, {
              state: {
                trackData: response.data,
                curatorName: curatorName,
              },
            });
          } else {
            console.error("Unexpected status:", response.status);
            navigate(ROUTES.PAGE_NOT_FOUND);
          }
        });
      }
      if (trackId && playlistId) {
        artistRefetch({ trackId: trackId }).then((response: any) => {
          if (response.status === "fulfilled") {
            navigate(`/submit-public-page/${playlistId}`, {
              state: { trackData: response.data },
            });
          } else {
            console.error("Unexpected status:", response.status);
            navigate(ROUTES.PAGE_NOT_FOUND, {
              state: { playlistId: playlistId },
            });
          }
        });
      }
      if (trackId && !playlistId && !curatorName) {
        artistRefetch({ trackId: trackId }).then((response: any) => {
          if (response.status === "fulfilled") {
            navigate(ROUTES.SUBMIT_A_SONG_1, {
              state: { trackData: response.data },
            });
          } else {
            console.error("Unexpected status:", response.status);
            navigate(ROUTES.PAGE_NOT_FOUND);
          }
        });
      }
      if (playlistId && !trackId) {
        curatorRefetch({ playlistId: playlistId }).then(
          (response: { data: any }) => {
            navigate(ROUTES.CONFIRM_PLAYLIST, {
              state: { playlistData: response.data },
            });
            if (response) {
              window.localStorage.setItem("playlist", "playlist");
            }
          }
        );
      }
    }
  }, [
    artistRefetch,
    curatorName,
    curatorRefetch,
    data?.user?.isBan,
    data?.userExist,
    isLoggedIn,
    navigate,
    playlistId,
    trackId,
  ]);
  const handleVisit = async () => {
    try {
      const user = {
        id,
      } as any;
      await visitUser(user).unwrap();
      refetch();
    } catch (error: any) {}
  };
  useEffect(() => {
    if (id) {
      handleVisit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleEmailNotificationChange = (event: {
    target: { checked: any };
  }) => {
    setEmailNotification(event.target.checked);
  };

  const handleTermsOptInChange = (event: { target: { checked: any } }) => {
    setTermsOptIn(event.target.checked);
  };

  const acceptTerms = () => {
    debugger;
    setTermsOptIn(true);
  };

  return (
    <>
      {data?.user?.isBan === true ? (
        <Box
          sx={{
            background: `url(${LogBackground4})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100vh",
              background: " #000000A6",
              position: "absolute",
              width: "100%",
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffffff",
                padding: "24px 44px",
                textAlign: "center",
                borderRadius: "12px",
              }}
            >
              <CancelOutlinedIcon
                sx={{
                  color: "red",
                  height: "50px",
                  width: "50px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                  color: themeColors["#FF0000"],
                }}
              >
                Oops!
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: themeFonts["Poppins-Regular"],
                  color: themeColors["#000000"],
                  marginTop: "12px",
                }}
              >
                Your Id is banned.
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : isLoading === true || data?.userExist === true ? (
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={50}
            sx={{
              color: "#00ADB5",
              fontWeight: "900",
              height: "90vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Box>
      ) : !termsOptIn ? (
        <Box
          sx={{
            background: `url(${LogBackground4})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100vh",
              background: " #000000A6",
              position: "absolute",
              width: "100%",
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                maxWidth: "1200px",
                width: "100%",
                backgroundColor: "#ffffff",
                padding: "0 0 50px 0",
                textAlign: "center",
                margin: isSmallScreen ? "30px 0 176px" : "30px 0",
                overflowX: "auto",
              }}
            >
              <TermsConditions
                showButton={showButton}
                acceptTerms={acceptTerms}
              />{" "}
              <Box
                sx={{
                  width: "100%",
                  padding: "24px 0px",
                  textAlign: "center",
                  margin: "10px 0",
                  position: "fixed",
                  right: "50%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  maxWidth: "1200px",
                  backgroundColor: "#08ADB5",
                  bottom: "20px",
                  display: isSmallScreen ? "block" : "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingY: "50px",
                  paddingX: "50px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      marginBottom: "10px",
                      textAlign: "center",
                      ":hover": {
                        background: "#00ADB5",
                      },
                    }}
                  >
                    To proceed, kindly review the Pitchplaylists Terms and
                    Privacy Policy
                  </Box>
                </Box>

                <Box>
                  <Button
                    onClick={() => {
                      navigate(ROUTES.HOME);
                    }}
                    variant="contained"
                    sx={{
                      height: "45px",
                      background: "#FFFFFF",
                      color: "#00ADB5",
                      fontSize: "16px",
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      textAlign: "center",
                      ":hover": {
                        background: "#ffffff",
                      },
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    onClick={acceptTerms}
                    variant="contained"
                    sx={{
                      height: "45px",
                      background: "#FFFFFF",
                      color: "#00ADB5",
                      fontSize: "16px",
                      marginBottom: "10px",
                      marginLeft: "5px",
                      fontFamily: themeFonts["Poppins-Medium"],
                      textAlign: "center",
                      ":hover": {
                        background: "#ffffff",
                      },
                    }}
                  >
                    Accept Terms and Privacy
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            background: `url(${LogBackground4})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100vh",
              background: " #000000A6",
              position: "absolute",
              width: "100%",
              zIndex: 9999,
              padding: "20px",
            }}
          >
            <Box
              sx={{
                maxWidth: "542px",
                width: "100%",
                backgroundColor: "#ffffff",
                padding: "24px 44px",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                  color: themeColors["#000000"],
                }}
              >
                Stay Up To Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: themeFonts["Poppins-Regular"],
                  color: "#00000080",
                  marginTop: "6px",
                }}
              >
                {/* Opt in our newsletter to keep updated on the latest features and
                promotions we are offering */}
                Opt in to our newsletter to stay informed about the latest
                updates.
              </Typography>

              <CustomCheckbox
                sx={{
                  marginTop: "24px",
                }}
                checked={emailNotification}
                onChange={handleEmailNotificationChange}
                callBack={callBack}
                label="I would like to get periodic updates and news regarding Pitchplaylists to my email associated with my Spotify account."
              />
              <CustomCheckbox
                sx={{
                  marginTop: "16px",
                }}
                label="I agree to the Pitchplaylists"
                secondaryLabel="terms & conditions. *"
                checked={termsOptIn}
                callBack={callBack}
                onChange={handleTermsOptInChange}
                to={ROUTES.TERMS_AND_CONDITIONS}
              />
              <Box
                sx={{
                  marginTop: "31px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={disabled}
                  sx={{
                    fontSize: isMiniScreen ? "12px" : "14px",
                    fontFamily: themeFonts["Poppins-Regular"],
                    height: isMiniScreen ? "auto" : "36px",
                    paddingLeft: "17px",
                    paddingRight: "17px",
                    boxShadow: "0 4px 4px #00000040",
                    color: "#FFFFFF",
                    background: "#00ADB5",
                    "&.Mui-disabled": {
                      color: "#00ADB5",
                      background: "#FFFFFF",
                    },
                    "&:hover": {
                      color: "#FFFFFF",
                      background: "#00ADB5",
                      border: "1px solid #0000001a",
                    },
                  }}
                >
                  Continue to PitchPlaylists
                </Button>
              </Box>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#00ADB5",
                  marginTop: "10px",
                }}
              >
                Required *
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
