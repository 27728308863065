import { ReactNode } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Box,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { themeColors, themeFonts } from "../../configs";
import { Link } from "react-router-dom";

interface CustomCheckboxProps {
  label: string | ReactNode;
  checkboxProps?: CheckboxProps;
  font?: keyof typeof themeFonts;
  fontFamily?: keyof typeof themeFonts;
  errorStatus?: string;
  helperText?: string;
  disabled?: boolean;
  sx?: object;
  fontWeight?: string;
  checked?: any;
  onChange?: any;
  callBack?: any;
  secondaryLabel?: any;
  selectingGenres?: any;
  to?: any;
}

export const CustomCheckbox = ({
  label,
  font = "Poppins-Regular",
  fontFamily,
  checkboxProps,
  errorStatus,
  helperText,
  disabled,
  sx,
  fontWeight,
  checked,
  onChange,
  callBack,
  secondaryLabel,
  selectingGenres,
  to,
}: CustomCheckboxProps) => {
  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        sx={{
          display: "flex",
          width: "fit-content",
          margin: 0,
          alignItems: "flex-start",
          "& span.MuiButtonBase-root": { padding: "0" },
        }}
        control={
          <Checkbox
            sx={{
              "& svg": { fontSize: "20px" },

              "& svg path": {
                border: "0.5px solid #1C223E6E !important",
                fill: callBack ? "#00ADB5" : "#1C223E6E",
                borderRadius: "3px",
                opacity: 1,
              },
            }}
            disabled={disabled}
            {...checkboxProps}
            checked={checked}
            onChange={onChange}
          />
        }
        label={
          <Box>
            {typeof label === "string" ? (
              <Typography
                sx={{
                  fontFamily: themeFonts["Poppins-Regular"],
                  color: selectingGenres ? "#000000" : "#00000080",
                  fontSize: selectingGenres ? 14 : "12px",
                  lineHeight: "18px",
                  position: "relative",
                  marginLeft: "6px",
                  textAlign: "start",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {label}
                <MuiLink
                  component={Link}
                  to={to}
                  target="_blank"
                  sx={{
                    fontFamily: themeFonts["Poppins-Regular"],
                    color: "#00ADB5",
                    fontSize: "12px",
                    lineHeight: "18px",
                    position: "relative",
                    marginLeft: "6px",
                    textAlign: "start",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {secondaryLabel}
                </MuiLink>
              </Typography>
            ) : (
              label
            )}
            {helperText && (
              <Typography
                sx={{
                  fontFamily: themeFonts[font],
                  color: themeColors["#000000"],
                  fontSize: 8,
                  fontWeight: 300,
                  position: "absolute",
                  width: "172px",
                }}
              >
                {helperText}
              </Typography>
            )}
          </Box>
        }
      />

      {/* <ErrorStatusMessage error={errorStatus} /> */}
    </FormGroup>
  );
};
