import {
  Box,
  // Button,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { LogBackground } from "../../../pngs";
import { Footer } from "../../footer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";
import { themeFonts } from "../../../configs";
import { TermsOfServices } from "./terms of services";
import { CookiesList } from "./cookies-list";
import { CookiesPolicy } from "./cookies-policy";
import { AcceptablePolicy } from "./acceptable-policy";
import { PrivacyPolicyCcp } from "./privacy-policy-ccpa";
// import { useNavigate } from "react-router-dom";
// import { ROUTES } from "../../../components/consts/routes.consts";
const TermsConditions = ({
  showButton = false,
  acceptTerms,
}: {
  showButton?: any;
  acceptTerms?: any;
}) => {
  const isMediumScreen = useMediaQuery("(max-width:1350px)");
  const isSmallScreen = useMediaQuery("(max-width:980px)");
  // const navigate = useNavigate();
  const [value, setValue] = React.useState("Terms Of Service");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: "0px !important",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <Box
        sx={{
          background: `url(${LogBackground})`,
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          marginTop: "-80px",
          paddingTop: "144px",
          paddingBottom: "70px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          className="faq-section-heading"
          sx={{
            fontSize: "36px",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: "1650px",
          width: "100%",
          margin: "auto",
          textAlign: "left",
          padding: isMediumScreen ? "40px 15px" : "80px 15px",
        }}
      >
        <Grid
          container
          sx={{
            position: "relative",
          }}
        >
          <TabContext value={value}>
            <Grid item xs={isMediumScreen ? 12 : 3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: isMediumScreen ? "unset" : "unset",
                }}
              >
                <TabList
                  orientation={isMediumScreen ? "horizontal" : "vertical"}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="lab API tabs example"
                  sx={{
                    width: "max-content",
                    minHeight: "45px !important",
                    overflow: "visible",
                    "& span.MuiTabs-indicator": {
                      backgroundColor: "#00ADB5",
                      left: isMediumScreen ? "-0.5px" : "-1px",
                      width: "2.5px",
                      overflow: "visible !important",
                    },
                    "& .MuiTabs-flexContainer": {
                      flexDirection: isMediumScreen ? "row" : "column",
                      minHeight: "45px !important",
                      borderLeft: isMediumScreen
                        ? "none"
                        : " 0.5px solid #00000040",
                      overflow: "visible !important",
                    },
                    "&.MuiTabs-vertical .MuiTabs-fixed": {
                      overflow: "visible !important",
                    },
                    "&.MuiTabs-root": {
                      width: "100%",
                    },
                    "&.MuiTabs-root .MuiTabScrollButton-root ": {
                      width: "20px",
                      opacity: isSmallScreen ? "1" : "0",
                      display: isSmallScreen ? "inline-flex" : "none",
                      color: "#00adb5",
                    },
                  }}
                >
                  <Tab
                    label="Terms Of Service"
                    value="Terms Of Service"
                    sx={{
                      color: "#00000080",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />
                  <Tab
                    label="Privacy Policy"
                    value="Privacy Policy"
                    sx={{
                      color: "#00000080",
                      borderRadius: "5px",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />

                  <Tab
                    label="Cookie List"
                    value="Cookie List"
                    sx={{
                      color: "#00000080",
                      borderRadius: "5px",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />

                  <Tab
                    label="Cookie policy"
                    value="Cookie policy"
                    sx={{
                      color: "#00000080",
                      borderRadius: "5px",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />
                  <Tab
                    label="Acceptable Use Policy"
                    value="Acceptable Use Policy"
                    sx={{
                      color: "#00000080",
                      borderRadius: "5px",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        // background: "#00ADB5",
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />

                  <Tab
                    label="privacy policy CCPA"
                    value="Privacy Policy for California Residents"
                    sx={{
                      color: "#00000080",
                      borderRadius: "5px",
                      width: isMediumScreen ? "auto" : "220px",
                      fontSize: "16px",
                      padding: "0px 16px",
                      alignItems: "flex-start",
                      minHeight: "45px !important",
                      fontFamily: themeFonts["Poppins-Regular"],
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        // background: "#00ADB5",
                        color: "#00ADB5",
                        fontFamily: themeFonts["Poppins-Bold"],
                      },
                    }}
                  />
                </TabList>
              </Box>
            </Grid>

            <Grid item xs={isMediumScreen ? 12 : 9}>
              <TabPanel
                value="Terms Of Service"
                sx={{
                  padding: "0 !important",
                }}
              >
                <TermsOfServices isMediumScreen={isMediumScreen} />
              </TabPanel>

              <TabPanel
                value="Privacy Policy"
                sx={{
                  padding: "0 !important",
                }}
              >
                <>
                  <Box
                    sx={{
                      width: "100%",
                      padding: "32px",
                      border: "0.5px solid #00000040",
                      borderRadius: "16px 16px 0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        paddingRight: isMediumScreen ? "0px" : "24px",
                      }}
                    >
                      <Typography
                        className="terms-sub-heading"
                        sx={{ paddingTop: "0px" }}
                      >
                        Introduction
                      </Typography>
                      <Typography className="terms-about-text">
                        MUZQ SIA hereinafter referred to as ("We, Our, Us,
                        Pitchplaylists, Service Provider") respects your privacy
                        and is committed to protecting it through our compliance
                        with this policy.
                      </Typography>
                      <Typography className="terms-about-text">
                        This policy describes the types of information we may
                        collect from you or that you may provide when you visit
                        the website{" "}
                        <Link
                          // to={"https://pitch-playlist.vercel.app/"}
                          to={"https://pitchplaylists.com/"}
                          target="_blank"
                          className="terms-link-text"
                        >
                          https://pitchplaylists.com/
                        </Link>{" "}
                        (our "Website") and our practices for collecting, using,
                        maintaining, protecting, and disclosing that information
                      </Typography>
                      <Typography className="terms-about-text">
                        This policy applies to the information we collect:
                        <ul>
                          <li>On this Website.</li>
                          <li>
                            In email and other electronic messages between you
                            and this Website.
                          </li>
                          <li>
                            On this Website. In email and other electronic
                            messages between you and this Website. When you
                            interact with our applications on third-party
                            websites and services those applications include
                            links to this policy.
                          </li>
                        </ul>
                      </Typography>{" "}
                      <Typography className="terms-about-text">
                        It does not apply to information collected by:
                        <ul>
                          <li>
                            Us offline or through any other means, including on
                            any other website operated by the Company or any
                            third party (including our affiliates and
                            subsidiaries); or
                          </li>
                          <li>
                            Any third party including our subsidiaries,
                            including through any application or content that
                            may link to or be accessible from or through the
                            Website.
                          </li>
                        </ul>
                      </Typography>
                      <Typography className="terms-about-text">
                        Please read this policy carefully to understand our
                        policies and practices regarding your information and
                        how we will treat it. If you do not agree with our
                        policies and practices, your choice is to not use our
                        Website. By accessing or using this Website, you agree
                        to this privacy policy. This policy may change from time
                        to time (see Changes to Our Privacy Policy). Your
                        continued use of this Website after we make changes is
                        deemed to be acceptance of those changes, so please
                        check the policy periodically for updates. Use of the
                        Website and Services is governed by these Terms of
                        Service, Acceptable Use Policy, and our Privacy Policy.
                      </Typography>
                      <Box>
                        <Typography className="terms-sub-heading">
                          1. Individuals under the age of 18 years
                        </Typography>
                        <Typography className="terms-about-text">
                          Our Website is not intended for individuals under 18
                          years of age. No one under the age of 18 may provide
                          any information to or on the Website. We do not
                          knowingly collect personal information from
                          individuals under 18. If you are under 18, do not use
                          or provide any information on this Website or through
                          any of its features, register on the Website, make any
                          purchases through the Website, use any of the
                          interactive or public comment features of this
                          Website, or provide any information about yourself to
                          us, including your name, address, telephone number,
                          email address, or any screen name or user name you may
                          use. If we learn we have collected or received
                          personal information from an individual under 18
                          without proper authorization, we will delete that
                          information. If you believe we might have any
                          information from or about an individual under 18,
                          please contact us using the information provided in
                          the Contact section below.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          2. Information We Collect About You and How We Collect
                          It
                        </Typography>
                        <Typography className="terms-about-text">
                          We collect several types of information from and about
                          users of our Website, including information:
                          <Typography className="terms-sub-title">
                            <ul>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Website Visitors and General Consumers:
                                  </span>{" "}
                                  We collect information about website visitors,
                                  who may not directly interact with us, using
                                  it for analytics and research purposes, please
                                  see the Technology and cookies and Trackers
                                  section below for more information. If you
                                  interact on our website, we will track your
                                  activities online, but we do not know who you
                                  are. However, if you submit a form with your
                                  contact information, we will engage with you
                                  as requested and may follow up with you to
                                  further build a relationship.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Technical data,
                                  </span>{" "}
                                  such as User's IP addresses, is essential for
                                  security measures, and fraud prevention.
                                  Device information helps optimize the
                                  experience based on the device used, and
                                  browser type and version ensure compatibility
                                  with the platform and improve user interface
                                  design.
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Analytical data,
                                  </span>{" "}
                                  like your browsing history on the platform, is
                                  analyzed to understand your interests and
                                  improve content recommendations. Music
                                  preferences are monitored to enhance website
                                  design and user engagement.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    User Type:
                                  </span>{" "}
                                  Identification of whether you are an artist
                                  submitting music or a curator who has added
                                  playlists to our site.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Curator Information:
                                  </span>{" "}
                                  If you are a curator, we record the playlists
                                  you have added to our site. The country of
                                  curators who have added playlists is visible
                                  to all users.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Public Pages and Landing Pages:
                                  </span>{" "}
                                  As a registered curator, you can create public
                                  landing pages featuring your playlists. These
                                  pages allow other users to submit songs
                                  directly to your playlist. We also provide
                                  unique links for curators to share on social
                                  media or other platforms, enabling easy song
                                  submissions from artists.
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Referral Program:
                                  </span>{" "}
                                  Our referral program enables curators to share
                                  unique invite links from their profiles. When
                                  a new user clicks on a referral link and
                                  registers, they can become a curator by adding
                                  at least one playlist to the website. If the
                                  new user becomes a curator, the original
                                  curator who shared the link earns bonus points
                                  and moves up in the curator leaderboard.
                                </Typography>
                              </li> */}
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Artist Information:
                                  </span>{" "}
                                  If you are an artist using our platform, we
                                  collect various types of information to
                                  improve your experience and tailor our
                                  services to your needs. This includes details
                                  about the music or tracks you search for and
                                  listen to, your location, and your username
                                  and social media pages linked to your Spotify
                                  account. We also collect information about
                                  your Spotify subscription type, the number of
                                  followers you have on Spotify, and the artists
                                  you follow. This information helps us
                                  understand your preferences and provide you
                                  with relevant suggestions and features on our
                                  platform.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Location data
                                  </span>{" "}
                                  is collected to provide a more personalized
                                  experience based on your geographical
                                  location. This information helps us offer
                                  relevant content, such as playlists or
                                  promotions, that are specific to your region.
                                  Additionally, knowing your location allows us
                                  to comply with local laws and regulations,
                                  tailor our services to different regions, and
                                  improve our overall service based on
                                  geographic trends and preferences.
                                </Typography>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    How do we collect this information?
                                  </span>{" "}
                                  This information is typically provided by you
                                  voluntarily when you link your Spotify account
                                  with our services. Where it is not voluntary,
                                  it may be in the realm of employment or legal
                                  proceedings and follow the requirements of
                                  applicable law.
                                </Typography>{" "}
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Why do we collect this information?
                                  </span>{" "}
                                  This is based on the requirement to provide
                                  you services or our legitimate interest to
                                  grow our business and understand our Customers
                                  and their needs better. Our legitimate
                                  interest in this relates to sending you the
                                  request, in which you may choose to respond or
                                  not and can choose to ask us not to contact
                                  you for those purposes. Given this, our
                                  legitimate interest is not outweighed by the
                                  risk to you.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  Our systems may automatically collect log
                                  data, including IP addresses, device
                                  information, browser type, access times, and
                                  other details, to help diagnose issues and
                                  manage our services effectively and
                                  individually does not identify you
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  About your internet connection, the equipment
                                  you use to access our Website, and usage
                                  details.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                        <Typography className="terms-about-text">
                          We collect this information:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  Directly from you when you link your Spotify
                                  account.
                                </Typography>
                              </li>

                              <li>
                                <Typography className="terms-about-text">
                                  Automatically as you navigate through the
                                  site, information collected automatically may
                                  include usage details, IP addresses, and
                                  information collected through cookies.
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  From third parties, for example, our business
                                  partners.
                                </Typography>
                              </li> */}
                            </ul>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          3. Information You Provide to Us
                        </Typography>
                        <Typography className="terms-about-text">
                          By engaging with us, you provide us with information
                          directly for your Account, Services, and Finance. More
                          detail is provided below for each one of these, but
                          here we explain how and why we process this
                          information:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Registration Information:
                                  </span>{" "}
                                  When you sign up for an account with Spotify,
                                  we collect your email address and username,
                                  provided by Spotify.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Profile Information:
                                  </span>{" "}
                                  Your profile information, including your email
                                  and username, is automatically imported from
                                  Spotify. Please be aware that this data is
                                  collected directly and cannot be modified
                                  within our platform.
                                  {/* Your profile information is directly imported
                                  from Spotify, including details such as your
                                  location and social media links. Please note
                                  that this information is collected
                                  automatically and cannot be edited on our
                                  platform. */}
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Submission Details:
                                  </span>{" "}
                                  If you are an artist submitting music or a
                                  curator adding playlists, we collect
                                  information about the songs you submit and the
                                  playlists you create.
                                </Typography>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Feedback and Interactions:
                                  </span>{" "}
                                </Typography>
                              </li>

                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    For Artists:
                                  </span>{" "}
                                  When a curator accepts or rejects your song
                                  submission, we collect this feedback to
                                  provide information to users if track is
                                  approved or not.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    For Curators:
                                  </span>{" "}
                                  Information about the playlists you add,
                                  including their titles and descriptions, helps
                                  us understand your curation preferences and
                                  improve our services
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Payment Information:
                                  </span>{" "}
                                  If you choose to purchase additional credits
                                  or services, we collect payment information to
                                  process your transactions securely.
                                  Transaction history keeps track of User's
                                  spending and earnings within the platform.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  Information you provide by completing forms on
                                  our website includes details given during
                                  registration, service subscriptions, material
                                  submissions, or requests for additional
                                  services. We may also request information when
                                  you report an issue with our website.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  Records and copies of your correspondence
                                  including email addresses, if you contact us.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  Your responses to surveys that we might ask
                                  you to complete for research purposes.
                                </Typography>
                                {/* <Typography className="terms-about-text">
                                  Details of transactions you carry out through
                                  our Website and of the fulfillment of your
                                  orders. You may be required to provide
                                  financial information before placing an order
                                  through our Website.
                                </Typography>{" "} */}
                                <Typography className="terms-about-text">
                                  Your search queries on the Website.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                        <Typography className="terms-about-text">
                          You also may provide information, to be published or
                          displayed (hereinafter, "Posted") on public areas of
                          the Website, or transmitted to other users of the
                          Website or third parties (collectively, "User
                          Contributions"). Your User Contributions are posted on
                          and transmitted to others at your own risk. Although
                          we limit access to certain pages/you may set certain
                          privacy settings for such information by logging into
                          your account profile, please be aware that no security
                          measures are perfect or impenetrable. Additionally, we
                          cannot control the actions of other users of the
                          Website with whom you may choose to share your User
                          Contributions. Therefore, we cannot and do not
                          guarantee that your User Contributions will not be
                          viewed by unauthorized persons.
                        </Typography>

                        {/* <Typography className="terms-about-text">
                          <span className="terms-sub-title">
                            Information from other third parties.{" "}
                          </span>{" "}
                          <br></br> You will login to our services using a
                          linked account, such as Spotify. We make sure that you
                          are able to do this, but it does mean you may be
                          providing us with more information about you. You
                          should check the privacy notices of these providers to
                          see what they share with us. We may also get
                          information through third parties if you use them in
                          Classes or Activities. We try to control the use of
                          third party tools, but we are not perfect.
                        </Typography> */}
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          Information We Collect Through Automatic Data
                          Collection Technologies
                        </Typography>
                        <Typography className="terms-about-text">
                          As you navigate through and interact with our Website,
                          we may use automatic data collection technologies to
                          collect certain information about your equipment,
                          browsing actions, and patterns, including:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  Details of your visits to our Website,
                                  including traffic data, location data, logs,
                                  and other communication data and the resources
                                  that you access and use on the Website.
                                </Typography>
                              </li>

                              <li>
                                <Typography className="terms-about-text">
                                  Information about your computer and internet
                                  connection, including your IP address,
                                  operating system, and browser type.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                        <Typography className="terms-about-text">
                          The information we collect automatically may only be
                          statistical data and does not include personal
                          information, or we may maintain it or associate it
                          with personal information we collect in other ways or
                          receive from third parties. It helps us to improve our
                          Website:{" "}
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  Estimate our audience size and usage patterns.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  Store information about your preferences,
                                  allowing us to customize our Website according
                                  to your individual interests.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  Speed up your searches.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  Recognize you when you return to our Website.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                        <Typography className="terms-about-text">
                          The technologies we use for this automatic data
                          collection may include:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Cookies or browser cookies.
                                  </span>{" "}
                                  A cookie is a small file placed on the hard
                                  drive of your computer. You may refuse to
                                  accept browser cookies by activating the
                                  appropriate settings on your browser. However,
                                  if you select this setting you may be unable
                                  to access certain parts of our Website. Unless
                                  you have adjusted your browser setting so that
                                  it will refuse cookies, our system will issue
                                  cookies when you direct your browser to our
                                  Website.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Web Beacons.
                                  </span>{" "}
                                  Pages of our Website and our emails may
                                  contain small electronic files known as web
                                  beacons (also referred to as clear gifs, pixel
                                  tags, and single-pixel gifs) that permit the
                                  Company, for example, to count users who have
                                  visited those pages or opened an email and for
                                  other related website statistics for example,
                                  recording the popularity of certain website
                                  content and verifying system and server
                                  integrity.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Flash Cookies.
                                  </span>{" "}
                                  Certain features of our Website may use
                                  locally stored objects or Flash cookies to
                                  collect and store information about your
                                  preferences and navigation to, from, and on
                                  our Website. Flash cookies are not managed by
                                  the same browser settings as are used for
                                  browser cookies. For information about
                                  managing your privacy and security settings
                                  for Flash cookies, see Choices About How We
                                  Use and Disclose Your Information.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                          We do not collect personal information automatically,
                          but we may tie this information to personal
                          information about you that we collect from other
                          sources or you provide to us.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          paddingBottom: "52px",
                          borderBottom: "1px solid #00000080",
                        }}
                      >
                        <Typography className="terms-sub-heading">
                          5. How We Use Your Information
                        </Typography>
                        <Typography className="terms-about-text">
                          We use information that we collect about you or that
                          you provide to us, including any information:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  To present our Website and its contents to
                                  you.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To present and maintain landing pages
                                  featuring your playlists.
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  To provide you referral program, we use your
                                  information to track referrals and award bonus
                                  points to users who successfully refer new
                                  curators to our platform.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To manage credits earned through activities
                                  such as providing feedback on submissions.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  For Artists: We use your information to
                                  facilitate the submission of your music to
                                  playlist curators, allowing you to showcase
                                  your work to a broader audience.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  For Curators: We use your information to
                                  enable you to add and manage playlists on our
                                  platform, to share your curated playlists with
                                  others.
                                </Typography>
                              </li>
                              {/* <li>
                                <Typography className="terms-about-text">
                                  We use your information to personalize your
                                  experience on our platform. This includes
                                  recommending playlists, songs, and artists
                                  based on your listening history and
                                  preferences.
                                </Typography>
                              </li> */}
                              <li>
                                <Typography className="terms-about-text">
                                  To provide timely customer support via
                                  messages and chats.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To ensure compliance with age-related legal
                                  requirements.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To fulfill any other purpose for which you
                                  provide it.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To provide you with notices about your
                                  account, including expiration and renewal
                                  notices.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To carry out our obligations and enforce our
                                  rights arising from any contracts entered into
                                  between you and us, including for billing and
                                  collection.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To notify you about changes to our Website or
                                  any products or services we offer or provide
                                  through it.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To allow you to participate in interactive
                                  features on our Website.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  In any other way, we may describe when you
                                  provide the information.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  For any other purpose with your consent.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          6. Disclosure of Your Information
                        </Typography>

                        <Typography className="terms-about-text">
                          We won't share your information unless it's needed for
                          our business or we're required to do so by law. We may
                          disclose aggregated or anonymized information about
                          our users, and information that does not identify any
                          individual without restriction. We may disclose
                          information that we collect, or you provide as
                          described in this privacy policy:
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  To our subsidiaries and affiliates.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To contractors, service providers, and other
                                  third parties we use to support our business
                                  and who are bound by contractual obligations
                                  to keep personal information confidential and
                                  use it only for the purposes for which we
                                  disclose it to them.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To a buyer or other successor in the event of
                                  a merger, divestiture, restructuring,
                                  reorganization, dissolution, or other sale or
                                  transfer of some or all of Our's assets,
                                  whether as a going concern or as part of
                                  bankruptcy, liquidation, or similar
                                  proceeding, in which personal information held
                                  by Us about our Website users is among the
                                  assets transferred.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To fulfill the purpose for which you provide
                                  it. For example:
                                </Typography>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Customer Support:
                                  </span>{" "}
                                  When you contact our customer support team
                                  with inquiries, concerns, or requests for
                                  assistance, we use your information to address
                                  and resolve your specific issue.
                                </Typography>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Service Updates:
                                  </span>{" "}
                                  Users will be informed about service updates,
                                  maintenance schedules, and any critical
                                  changes that may affect their server's
                                  performance.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  For any other purpose disclosed by us when you
                                  provide the information, With your consent.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                        <Typography className="terms-about-text">
                          We may also disclose information:{" "}
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  To comply with any court order, law, or legal
                                  process, including to respond to any
                                  government or regulatory request.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  To enforce or apply our terms of use and other
                                  agreements, including for billing and
                                  collection purposes.
                                </Typography>
                              </li>
                              <li>
                                <Typography className="terms-about-text">
                                  If we believe disclosure is necessary or
                                  appropriate to protect our customers' rights,
                                  property, safety, or others.
                                </Typography>
                              </li>
                            </ul>
                          </Typography>
                        </Typography>
                      </Box>{" "}
                      <Box>
                        <Typography className="terms-sub-heading">
                          7. Choices About How We Use and Disclose Your
                          Information
                        </Typography>
                        <Typography className="terms-about-text">
                          Where permitted under applicable law, you have the
                          right to request access to your Information and
                          delete, change, or correct your information collected
                          by Us. You can access and update the information
                          associated with your account directly by logging into
                          your account.
                        </Typography>
                        <Typography className="terms-about-text">
                          We strive to provide you with choices regarding the
                          information you provide to us. Hence, We have created
                          mechanisms to provide you with the following control
                          over your information:{" "}
                          <Typography className="terms-sub-title">
                            <ul>
                              <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Cookie Management:
                                  </span>{" "}
                                  You can configure your browser to block all or
                                  certain cookies or to notify you when cookies
                                  are being sent. For managing Flash cookie
                                  settings, visit the Flash Player settings page
                                  on Adobe's website. Please be aware that
                                  disabling or blocking cookies may result in
                                  parts of this site becoming inaccessible or
                                  not functioning as intended. Additionally, you
                                  can disable cookies through the Preferences
                                  tab in your profile account settings.{" "}
                                </Typography>
                              </li>{" "}
                              {/* <li>
                                <Typography className="terms-about-text">
                                  <span className="terms-sub-title">
                                    Promotional Offers from the Company.
                                  </span>{" "}
                                  adjusting your user preferences in your
                                  account profile by checking or unchecking the
                                  relevant boxes or by sending us an email
                                  stating your request to
                                  <Link
                                    to={"mailto:info@pitchplaylists.com"}
                                    className="terms-link-text"
                                  >
                                    {" "}
                                    info@pitchplaylists.com{" "}
                                  </Link>{" "}
                                  If we have sent you a promotional email, you
                                  may send us a return email asking to be
                                  omitted from future email distributions. This
                                  opt out does not apply to information provided
                                  to the Company as a result of a product
                                  purchase, warranty registration, product
                                  service experience, or other transactions.
                                </Typography>
                              </li> */}
                            </ul>
                          </Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          8. Accessing and Correcting Your Information
                        </Typography>
                        <Typography className="terms-about-text">
                          Since we collect your account information from
                          Spotify, you are unable to review or access the
                          account information that we collect on your behalf. If
                          you wish to exercise your rights to review and access
                          account information under relevant privacy laws, you
                          must directly contact Spotify. However, you can
                          contact us to request the deletion of your account if
                          you choose to do so.
                        </Typography>
                        <Typography className="terms-about-text">
                          You may also send us an email at{" "}
                          <Link
                            to={"mailto:info@pitchplaylists.com"}
                            className="terms-link-text"
                          >
                            {" "}
                            info@pitchplaylists.com{" "}
                          </Link>
                          to request to delete any information that you have
                          been collected. We cannot delete your information
                          except by deleting your user account. We may not
                          accommodate a request to change information if we
                          believe the change would violate any law or legal
                          requirement or cause the information to be incorrect.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          9. How long we retain your data
                        </Typography>
                        <Typography className="terms-about-text">
                          Data shall be retained for a limited period not
                          exceeding that which is necessary for the purposes for
                          which it was collected to perform services or as
                          required by applicable laws and regulations. The
                          limited retention period shall be determined in
                          accordance with the nature and sensitivity of the
                          data, the purposes of processing, and any contractual
                          or legal obligations. Users are entitled to exercise
                          their rights under applicable data protection laws,
                          including the right to access, rectify, erase,
                          restrict processing, and withdraw consent, where
                          applicable. For further inquiries or to assert these
                          rights, individuals may contact the data controller
                          using the contact details provided in this Privacy
                          Policy.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          10. Security of Personal Information
                        </Typography>
                        <Typography className="terms-about-text">
                          We have instituted systems to make sure authorized
                          users can access our platform. We use SSL encryption
                          to keep data safe during transmission. We've added an
                          extra layer of security with two-factor
                          authentication. Additionally, we've taken steps to
                          secure data storage, limit access to information,
                          provide privacy training, and regularly review and
                          audit our security measures to make everything as safe
                          as possible and to enhance overall security to protect
                          the data we collect from you against loss, misuse,
                          unauthorized access, alteration, or destruction.
                        </Typography>{" "}
                        <Typography className="terms-about-text">
                          However, The safety and security of your information
                          also depend on you. it is important to acknowledge
                          that despite these efforts, no data security measures
                          can guarantee absolute security or confidentiality. We
                          strongly encourage you to exercise due diligence in
                          safeguarding your information. Responsible steps
                          include ensuring to log out after using a shared
                          computer, choosing a robust password that remains
                          confidential and is not easily ascertainable, and
                          keeping your login and password information private.
                          In the event of data transfer, We commit to ensuring
                          the secure transfer of information, adhering to
                          privacy laws. With the use of encryption protocols and
                          industry-standard practices to safeguard the
                          confidentiality and integrity of the data. Please be
                          aware that we bear no responsibility for lost, stolen,
                          or compromised passwords, or for any unauthorized
                          account activity resulting from compromised passwords.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          11. Your Privacy Rights under GDPR
                        </Typography>
                        <Typography className="terms-about-text">
                          We would like to make sure you are fully aware of all
                          of your data protection rights under the GDPR. Every
                          user is entitled to the following:
                          <ol type="1">
                            <li>
                              The right to be informed - You must be informed by
                              Us how we use your Information through our Privacy
                              Policy.
                            </li>
                            <li>
                              The right to access – You have the right to
                              request Our Company for copies of your data. We
                              may charge you a small fee for this service.
                            </li>
                            <li>
                              The right to rectification – For the right to
                              rectification, please contact Spotify directly as
                              the information in question is collected from
                              them, and they can make the necessary corrections
                              or completions.
                            </li>
                            <li>
                              The right to erasure – You have the right to
                              request that Our Company erase your data, under
                              certain conditions.
                            </li>{" "}
                            <li>
                              The right to restrict processing – You have the
                              right to request that Our Company restrict the
                              processing of your data, under certain conditions.
                            </li>
                            <li>
                              The right to object to processing – You have the
                              right to object to Our Company’s processing of
                              your data, under certain conditions.
                            </li>{" "}
                            <li>
                              The right to data portability – You have the right
                              to request that Our Company transfer the data we
                              have collected to another organization, or
                              directly to you, under certain conditions.
                            </li>
                            <li>
                              The right related to automated decision-making and
                              profiling - Based on your consent or the
                              performance of a contract with you, you have the
                              right to be provided your data in machine-readable
                              format so that they can be ported to a replacement
                              service provider.
                            </li>
                          </ol>{" "}
                        </Typography>{" "}
                        <Typography className="terms-about-text">
                          These rights are applicable based on your location and
                          the relevant Data Protection Laws, and they provide
                          you with control and transparency over how your data
                          is handled. Please note that certain conditions and
                          exceptions may apply to these rights as outlined in
                          the respective Data Protection Laws.
                        </Typography>{" "}
                        <Typography className="terms-about-text">
                          If you make a request, we have one month to respond to
                          you. If you would like to exercise any of these
                          rights, please contact us at Email:{" "}
                          <Link
                            to={"mailto:info@pitchplaylists.com"}
                            className="terms-link-text"
                          >
                            {" "}
                            info@pitchplaylists.com{" "}
                          </Link>{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          12. Changes to Our Privacy Policy
                        </Typography>
                        <Typography className="terms-about-text">
                          It is our policy to post any changes we make to our
                          privacy policy, on this page with a notice that the
                          privacy policy has been updated and on the Website
                          home page. In the event of a material policy relevant
                          change to our privacy policy, like how we treat our
                          users' information, we are committed to providing
                          explicit notice to our users. we will personally
                          notify you by emailing to the primary email address
                          specified in your account. This notice will include
                          options for users to opt-in or opt-out, ensuring
                          transparency and affording individuals the opportunity
                          to make informed choices regarding their data, In
                          event of non privacy relevant changes to our policy
                          please find general notice on the Website home page or
                          general notice in privacy policy. Please refer to,
                          date privacy policy was last revised is identified at
                          the top of page. Note, You are responsible for
                          ensuring we have an up-to-date active and deliverable
                          email address for you, and for periodically visiting
                          our Website and this privacy policy to check for any
                          changes.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="terms-sub-heading">
                          13. Contact Information
                        </Typography>
                        <Typography className="terms-about-text">
                          If you have any questions, concerns, or requests
                          regarding your data, this Privacy Policy, or our data
                          practices, please do not hesitate to contact us. You
                          may reach our Privacy Team at the following contact
                          information: Email Address:-{" "}
                          <Link
                            to={"mailto:info@pitchplaylists.com"}
                            className="terms-link-text"
                          >
                            {" "}
                            info@pitchplaylists.com{" "}
                          </Link>{" "}
                        </Typography>
                        <Typography className="terms-about-text">
                          We are committed to addressing your inquiries promptly
                          and in accordance with applicable data protection
                          laws. Your privacy is important to us, and we
                          appreciate your trust in our handling of your
                          information.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              </TabPanel>

              <TabPanel
                value="Cookie List"
                sx={{
                  padding: "0 !important",
                }}
              >
                <CookiesList isMediumScreen={isMediumScreen} />
              </TabPanel>

              <TabPanel
                value="Cookie policy"
                sx={{
                  padding: "0 !important",
                }}
              >
                <>
                  <CookiesPolicy isMediumScreen={isMediumScreen} />
                </>
              </TabPanel>

              <TabPanel
                value="Acceptable Use Policy"
                sx={{
                  padding: "0 !important",
                }}
              >
                <>
                  <AcceptablePolicy isMediumScreen={isMediumScreen} />
                </>
              </TabPanel>

              <TabPanel
                value="Privacy Policy for California Residents"
                sx={{
                  padding: "0 !important",
                }}
              >
                <>
                  <PrivacyPolicyCcp isMediumScreen={isMediumScreen} />
                </>
              </TabPanel>
            </Grid>
          </TabContext>
        </Grid>
      </Box>
      {!showButton && <Footer />}
    </Grid>
  );
};

export default TermsConditions;
